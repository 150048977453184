import './css/sdk.css';

function App() {
  return (
    <>
      <div className="navbar">
        <h1 className='logo'>Altex Studios</h1>
        <a className='cta-btn' href='https://discord.gg/BCYprfT2Ge'>
          <button><i class="fa-brands fa-discord"></i>Discord</button>
        </a>
      </div>

      <div className="content">
        <div className="content-hero">
          <h1 className='content-hero-title'>Altex Studios</h1>
          <p className='content-hero-subtitle'>Developing Immersive Experiences.</p>
        </div>
      </div>

      

      {/* <div className="overlay error">
        <div className="overlay-content">
          <h2>Small Device Detected</h2>
          <p>You aren't allowed to view this page on this device. Please use a larger device.</p>
        </div>
      </div> */}
    </>
  );
}

export default App;
